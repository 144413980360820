import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule, MatIconModule, MatButtonModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import { RoutingModule } from './routing/routing.module';
import { TableComponent } from './custom-components/table/table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  entryComponents: [TableComponent],
  declarations: [
    AppComponent,
    TableComponent
  ],
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTabsModule,
    RoutingModule,
    HttpClientModule,
    MatButtonModule,
    
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
