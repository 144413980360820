import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import json from './structure_PERUSTIEDOT_TARKASTUS.json';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementMap } from 'ngx-taho-forms';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public themeUrl: string = 'https://unpkg.com/@angular/material/prebuilt-themes/indigo-pink.css';

  public elementMapTarkastus: ElementMap = json;

  public hiddenValues: any = {
    tarkastusperuste: 100901659,
    '1_1_Näytenro_kentta': 'jeebo',
    '2_2_Tulos_kentta': 'jotain',
    tapahtumasuunnittelu: 33333,
    toimiala: 10000001

  };

  public tabit: any = [

    {
      id: 'tarkastus',
      label: 'Tarkastus',
      theme: 0,
      json: this.elementMapTarkastus,
      settings: { updateInterval: 0, autosave: true, NavigationButtons: false }

    },

    {
      id: 'yhteydenotto',
      label: 'Yhteydenotto',
      theme: 0,
      settings: { updateInterval: 0, autosave: true, hideNavigationButtons: true }

    },
    {
      id: 'heh01',
      label: 'HEH01',
      theme: 0
    }, {
      id: 'te42',
      label: 'Te42',
      theme: 0,
      defaultValues: {
        '1_2_Arvo_kentta': 'K'
      }
    },

    {
      id: 'ilppa',
      label: 'Ilppa',
      theme: 1,
      settings: { autosave: true, updateInterval: 2000 }
    },
    {
      id: 'newform',
      label: 'Lisää uusi'
    }
  ];

  public tabitDemo = [
    {
      id: 'ilppa',
      label: 'Ilppa',
      theme: 1,
      settings: { autosave: true, updateInterval: 2000 }
    },
    {
      id: 'newform',
      label: 'Lisää uusi'
    }

  ];
  
  ;

  public activeLink: string; // = this.tabit[0].id;

  public constructor(private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {


    console.log('url', window.location.href);
   
  }


  public savePage(allValues: { [key: string]: any }): void {
    console.log('all values', allValues);
  }


  public get tabsCurrent(): ReadonlyArray<any> {
    return this.isDemoPage() ? this.tabitDemo : this.tabit;
  }

  private isDemoPage(): boolean {
    return window.location.href.indexOf('forms.tahoo.fi') > -1
  }

}
