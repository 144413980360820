import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TahoCustomComponent } from 'ngx-taho-forms';

@Component({
  templateUrl: './table.component.html'
})
export class TableComponent extends TahoCustomComponent implements OnInit {
  

  public form: FormGroup;

  public constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    
  }

  public createForm(): FormGroup {
    this.form = this.fb.group({
      maa: [this.allValues['maa'], [Validators.required]]
    });
    return this.form;
  }

 

}