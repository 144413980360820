import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ilppa',
    pathMatch: 'full'
  }, 

  {
    path: 'ilppa',
    loadChildren: './../ilppa/ilppa.module#IlppaModule'
  },
  {
    path: 'basic',
    loadChildren: './../basic/basic.module#BasicModule'
  },

  {
    path: 'material',
    loadChildren: './../material/material.module#MaterialModule'

  },
  {
    path: 'newform',
    loadChildren: './../new-form/new-form.module#NewFormModule'
  }
];


@NgModule({

  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: true

    })
  ],
  exports: [RouterModule]
})
export class RoutingModule {


}
